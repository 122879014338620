<template>
  <div>

    <steps :passive-steps="[]" :active-step="7" :finished-steps="[1, 2, 3, 4, 5, 6, 7]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Certificates are successfully installed and ready to use') }}</h1>
        <h2 class="title is-5">{{ $t('You can remove the security token') }}</h2>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Information about the order') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <table>
                    <tbody>
                    <tr>
                      <td class="is-3">{{ $t('Order number') }}</td>
                      <td class="is-9">{{order}}</td>
                    </tr>
                    <tr>
                      <td v-if="role == '2'">{{ $t('Individual entrepreneur name') }}</td>
                      <td v-else>{{ $t('Company name') }}</td>
                      <td>{{OrgName}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Order status') }}</td>
                      <td>{{ $t('Closed') }}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Full name') }}</td>
                      <td>{{fio}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email') }}</td>
                      <td>{{email}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>

              <router-link class="button is-primary is-large is-flex-mobile" to="/">{{ $t('Go back to the main page') }}</router-link>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepSevenDotThree',
  components: {
    steps
  },
  props: [
    'currentForm',
    'order',
    'params'
  ],
  computed: {
    OrgName () {
      return this.params ? (this.params.legal ? this.params.legal + ' ' + this.params.name : this.params.name) : null
    },
    fio () {
      return this.params ? this.params.fio : null
    },
    email () {
      return this.params ? this.params.e_mail : null
    },
    phone () {
      return this.params ? this.params.tel_number : null
    },
    role () {
      return this.params ? this.params.role : null
    }
  },
  mounted () {
    console.log('Phone')
    console.log(this.phone)
    console.log('Params')
    console.log(this.params)
    console.log('CurrentForm')
    console.log(this.currentForm)
    console.log('Order')
    console.log(this.order)
  }
}
</script>

<style scoped>
  .has-user-border {
    border: 1px solid #339968;
  }
</style>
