<template>
  <div>

    <steps :passive-steps="[7]" :active-step="6" :finished-steps="[1, 2, 3, 4, 5]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3 is-marginless">{{ $t('Step 6') }}. {{ $t('Sign the digital signature key certificate') }}</h1>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <p class="title is-5">1. {{ $t('Print the EDS key certificate form') }}.</p>
              <a class="button" @click="print"><span class="file-icon"><img src="@/assets/icons/print.svg"></span><span>{{ $t('Print') }}</span></a>
              <a class="button" @click="save"><span class="file-icon"><img src="@/assets/icons/download.svg"></span><span>{{ $t('Save') }}</span></a>
              <p class="help">{{ $t('If there are any printing problems for cert') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5 is-marginless">2. {{ $t('Put the handwritten signature of the owner of the EDS key certificate on the printed certificate form') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5 is-marginless">3. {{ $t('Scan the signed digital signature key certificate') }}.</p>
              <p class="help">{{ $t('Save the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)') }}.</p>
              <div style="height: 1rem;"></div>
              <p class="title is-5">4. {{ $t('Attach the scanned and signed EDS key certificate form') }}.</p>

              <div class="file has-name is-primary">
                <label class="file-label">
                  <input id="documentScan" name="Certificate" @change="processFile" class="file-input" accept=".jpg,.png,.pdf,.tif" type="file">
                  <input name="fileName" type="hidden" :value="{inptFileName}"/>
                  <input name="fileBlob" type="hidden" :value="{inptFileBlob}"/>
                  <span class="file-cta">
                      <span class="file-icon"><img src="@/assets/icons/attach.svg"></span>
                      <span class="file-label">{{ $t('Attach an certificate') }}</span>
                  </span>
                  <span class="file-name" :class="{'has-text-danger': isTextDanger, 'has-text-primary': isTextPrimary}">{{fileText}}</span>
                </label>
              </div>

              <div style="height: 2rem;"></div>

              <div class="buttons">
                <input class="button is-primary is-large" type="button" :disabled="!inptFileName" v-on:click="GoToStepSeven" v-bind:value="$t('Next →')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <certificateForm :order="order" :fio="fio" :serialNumber="serialNumber" :validFrom="validFrom" :validTo="validTo" :supplier="supplier" :owner="owner" :publicKey="publicKey" :OID="OID" :policy="policy"
                     :supplierKeyID="supplierKeyID" :ownerKeyID="ownerKeyID" :keyUsage="keyUsage" :extendedKeyUsage="extendedKeyUsage" :signature="signature" :certificate="certificate" :role="role"/>

  </div>
</template>

<script>
import steps from '../steps/Steps'
import certificateForm from '../steps/Certificate'
import fileSaver from 'file-saver'
export default {
  name: 'OrderStepSixDotOne',
  components: {
    steps,
    certificateForm
  },
  props: [
    'currentForm',
    'order',
    'params'
  ],
  data () {
    return {
      isTextDanger: true,
      isTextPrimary: false,
      info: null,
      certificate: null,
      fileSize: null,
      inptFileName: null,
      inptFileBlob: null,
      serialNumber: null,
      validFrom: null,
      validTo: null,
      supplier: null,
      owner: null,
      publicKey: null,
      OID: null,
      policy: null,
      supplierKeyID: null,
      ownerKeyID: null,
      keyUsage: [],
      extendedKeyUsage: null,
      signature: null,
      cert: null,
      fileLabel: null,
      objectsRSA: null
    }
  },
  watch: {
    isSocket: {
      handler: function (val) {
        if (val) {
          this.checkAll()
        }
      },
      immediate: true
    }
  },
  computed: {
    isSocket () {
      return this.$store.state.socket
    },
    fio () {
      return this.params ? this.params.fio : null
    },
    role () {
      return this.params ? this.params.role : null
    },
    fileText () {
      return this.fileLabel ? this.fileLabel : this.$i18n.t('No document selected').toString()
    }
  },
  methods: {
    docScanValidate () {
      let _validFileExtensions = ['.jpg', '.png', '.pdf', '.tif']
      let myInput = document.getElementById('documentScan')
      if (myInput.type == 'file') {
        let sFileName = myInput.value
        if (sFileName.length > 0) {
          let blnValid = false
          for (let j = 0; j < _validFileExtensions.length; j++) {
            let sCurExtension = _validFileExtensions[j]
            if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
              blnValid = true
              break
            }
          }
          if (!blnValid) {
            this.scanId = null
            alert(this.$i18n.t('Upload the file in JPG, PNG, TIF, PDF format (file size shall not exceed 2 MB)'))
            myInput.value = null
            return false
          }
        }
      }
      return true
    },
    processFile (event) {
      this.docScanValidate()
      if (event) {
        const file = event.target.files[0]
        if (file) {
          if (file.size > 2097152) {
            this.fileLabel = this.$i18n.t('File size should not exceed 2MB')
            this.inptFileName = null
            this.inptFileBlob = null
            this.isTextDanger = true
            this.isTextPrimary = false
          } else {
            this.fileLabel = file.name
            this.isTextDanger = false
            this.isTextPrimary = true
            const reader = new FileReader()
            reader.onload = e => this.inptFileBlob = (e.target.result).split(',')[1]
            reader.readAsDataURL(file)
            this.inptFileName = file.name
          }
        } else {
          this.fileLabel = this.$i18n.t('No document selected')
          this.inptFileName = null
          this.inptFileBlob = null
          this.isTextDanger = true
          this.isTextPrimary = false
        }
      }
    },
    GoToStepSeven () {
      this.$validator.validateAll().then((result) => {
        this.$http.post('/certificate/doc/', { order: this.order, fileName: this.inptFileName, fileBlob: this.inptFileBlob, doc: 'doc' }).then(response => {
          if (response.data) {
            this.$emit('update:currentForm', 'FormStepSeven')
          }
        })
      })
    },
    PromiseGetCert () {
      return new Promise(resolve => {
        this.$http.get('/query/get-certificates/', { params: { order: this.order } }).then(response => {
          if (response.data) {
            this.certificates = response.data
            resolve(true)
          }
        })
      })
    },
    PromiseSetForm () {
      return new Promise(resolve => {
        this.certificate = this.certificates[1].certificate
        this.serialNumber = this.info.serialNumber
        this.validFrom = this.info.validFrom
        this.validTo = this.info.validTo
        this.supplier = this.info.issuerName
        this.owner = this.info.subjectName

        let publicKeyArray = []
        let publicKeyStr = this.info.keyBlob
        let index = 0
        let html = ''
        while (index <= publicKeyStr.length) {
          publicKeyArray.push(publicKeyStr.substr(index, 75))
          index += 70
        }
        for (let i = 0; i < publicKeyArray.length; i++) { html += publicKeyArray[i] + '<br>' }
        this.publicKey = html

        if (this.info.algOID === '1.3.6.1.4.1.6801.1.5.8' || this.info.algOID === '1.2.398.3.10.1.1.1.1.1') {
          this.OID = 'ГОСТ 34.310 - 2004'
        } else {
          this.OID = this.info.algOID
        }

        this.policy = this.info.policy
        this.supplierKeyID = this.info.subUniqID
        this.ownerKeyID = this.info.issUniID

        if (this.info.keyUsage[0] === '1') {
          this.keyUsage.push('digitalSignature')
        }
        if (this.info.keyUsage[1] === '1') {
          this.keyUsage.push('nonRepudiation')
        }
        if (this.info.keyUsage[2] === '1') {
          this.keyUsage.push('keyEncipherment')
        }
        this.keyUsage = this.keyUsage.join(', ')

        this.extendedKeyUsage = this.info.eku

        let signatureArray = []
        let signatureStr = this.info.certSign
        html = ''
        index = 0
        while (index <= signatureStr.length) {
          signatureArray.push(signatureStr.substr(index, 85))
          index += 60
        }
        for (let i = 0; i < signatureArray.length; i++) { html += signatureArray[i] + '<br>' }
        this.signature = html
        resolve(true)
      })
    },
    async checkAll () {
      await this.PromiseGetCert()
      this.info = await this.cryptoSocket.getCertificateInfo({ id: 2, certificate: this.certificates[1].certificate, objectName: this.order })
      await this.PromiseSetForm()
    },
    print () {
      const printContents = document.getElementById('certificateTable').innerHTML
      const WinPrint = window.open('', '', 'left=50,top=50,width=900,height=750,toolbar=0,scrollbars=1,status=0')
      WinPrint.document.open()
      WinPrint.document.write(printContents)
      WinPrint.document.close()
      WinPrint.focus()
      WinPrint.print()
      WinPrint.close()
    },
    save () {
      const certBody = '<!DOCTYPE><html><head><meta http-equiv="content-type" content="text/html; charset=utf-8"></head><body>' + document.getElementById('certificateTable').innerHTML + '</body></html>'
      this.$http.post('/certificate/pdf-make', { content: certBody, order: this.order }, {
        responseType: 'blob'
      }).then(response => {
        if (response.data) {
          const blob = new Blob([response.data], { type: 'application/octet-stream' })
          fileSaver.saveAs(blob, 'Certificate_' + this.order + '.pdf')
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .file-name {
    max-width: 700px !important;
  }
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
</style>
