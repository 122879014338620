<template>
  <div>

    <steps :passive-steps="[]" :active-step="7" :finished-steps="[1, 2, 3, 4, 5, 6]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Installing certificates') }}</h1>
        <p class="has-text-danger">{{ $t('Please, do not remove the security token before the end of operation') }}!</p>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(1), 'has-text-danger': isTextDanger(1), 'has-text-primary': isTextPrimary(1), 'is-marginless': isTextMarginless(1)}">
                    {{tryTokenText}}
                  </p>
                </div>
                <div class="media-right">
                  <img :src="tryToken" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(2), 'has-text-danger': isTextDanger(2), 'has-text-primary': isTextPrimary(2), 'is-marginless': isTextMarginless(2)}"
                     v-html="setCertificateGOSTText"></p>
                </div>
                <div class="media-right">
                  <img :src="setCertificateGOST" width="32">
                </div>
              </div>
              <div class="media">
                <div class="media-content">
                  <p :class="{'has-text-grey-light': isTextGreyLight(3), 'has-text-danger': isTextDanger(3), 'has-text-primary': isTextPrimary(3), 'is-marginless': isTextMarginless(3)}"
                     v-html="setCertificateRSAText"></p>
                </div>
                <div class="media-right">
                  <img :src="setCertificateRSA" width="32">
                </div>
              </div>

              <div v-if="catchErrorRefresh">
                <div style="height: 2em;"></div>
                <input class="button is-primary is-medium" type="button" v-on:click="TryAgainRefresh" v-bind:value="$t('← Back')">
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import steps from '../steps/Steps'
  import keyProblem from '../components/KeyProblem'
  export default {
    name: 'OrderStepSevenDotTwo',
    components: {
      steps,
      keyProblem
    },
    props: [
      'currentForm',
      'paramsForOrder'
    ],
    data () {
      return {
        textGreyLight: [1, 2, 3],
        textDanger: [],
        textPrimary: [],
        textMarginless: [],
        allChecked: false,
        tryToken: require('../assets/icons/check_silver.svg'),
        setCertificateRSA: require('../assets/icons/check_silver.svg'),
        setCertificateRSAText: this.$i18n.t('Installing user certificate for encryption').toString(),
        setCertificateGOST: require('../assets/icons/check_silver.svg'),
        setCertificateGOSTText: this.$i18n.t('Installing user certificate for signing').toString(),
        devices: null,
        reader: null,
        serialNumber: null,
        memoryGOST: null,
        memoryRSA: null,
        objectsGOST: null,
        objectsRSA: null,
        contName: null,
        tryTokenTextError: null,
        catchErrorRefresh: false
      }
    },
    computed: {
      password () {
        return this.paramsForOrder ? this.paramsForOrder.password : null
      },
      serial () {
        return this.paramsForOrder ? this.paramsForOrder.serial : null
      },
      order () {
        return this.paramsForOrder ? this.paramsForOrder.order : null
      },
      certificates () {
        return this.paramsForOrder ? this.paramsForOrder.certificates : null
      },
      tryTokenText () {
        return this.tryTokenTextError ? this.tryTokenTextError : this.$i18n.t('Installing certificates in key container', [this.order]).toString()
      }
    },
    methods: {
      isTextGreyLight (id) {
        return this.textGreyLight.find(text => text === id)
      },
      isTextDanger (id) {
        return this.textDanger.find(text => text === id)
      },
      isTextPrimary (id) {
        return this.textPrimary.find(text => text === id)
      },
      isTextMarginless (id) {
        return this.textMarginless.find(text => text === id)
      },
      startStepOne () {
        this.tryToken = require('../assets/icons/loading.svg')
        this.textGreyLight = [2, 3]
      },
      endStepOne () {
        this.textPrimary = [1]
        this.tryToken = require('../assets/icons/check_green.svg')
      },
      startStepTwo () {
        this.setCertificateGOST = require('../assets/icons/loading.svg')
        this.textGreyLight = [3]
      },
      endStepTwo () {
        this.setCertificateGOSTText = this.$i18n.t('User certificate for signing is installed').toString()
        this.textPrimary = [1, 2]
        this.setCertificateGOST = require('../assets/icons/check_green.svg')
      },
      startStepThree () {
        this.setCertificateRSA = require('../assets/icons/loading.svg')
        this.textGreyLight = []
      },
      endStepThree () {
        this.setCertificateRSAText = this.$i18n.t('User certificate for encryption is installed').toString()
        this.textPrimary = [1, 2, 3]
        this.setCertificateRSA = require('../assets/icons/check_green.svg')
      },
      checkToken () {
        return new Promise((resolve, reject) => {
          this.serialNumber = this.serialNumber.serial
          this.objectsGOST = JSON.parse(this.objectsGOST.objects)
          this.objectsGOST = this.objectsGOST.objects
          this.objectsRSA = JSON.parse(this.objectsRSA.objects)
          this.objectsRSA = this.objectsRSA.objects
          let sizeGOST = '2048'
          let sizeRSA = '5120'
          let error = null
          let errorReject = null
          if (this.serial !== this.serialNumber) {
            error = this.$i18n.t('Key generation used by other devices').toString()
            errorReject = 'Serial number = ' + this.serialNumber
          }
          if(this.memoryGOST.count < 9110) this.memoryGOST.count = 9110;
          if(this.memoryRSA.count < 9110) this.memoryRSA.count = 9110;
          if (!this.objectsGOST.length) {
            error = this.$i18n.t('The device does not contain objects of the GOST algorithm').toString()
          }
          if (!this.objectsRSA.length) {
            error = this.$i18n.t('The device does not contain objects of the RSA algorithm').toString()
          }
          if (parseInt(this.memoryGOST.count) < sizeGOST) {
            error = this.$i18n.t('Not enough memory on security token', [' alg=GOST']).toString()
            errorReject = 'memory GOST = ' + this.memoryGOST.count
          }
          if (parseInt(this.memoryRSA.count) < sizeRSA) {
            error = this.$i18n.t('Not enough memory on security token', [' alg=RSA']).toString()
            errorReject = 'memory RSA = ' + this.memoryRSA.count
          }

          // console.log(this.serialNumber.serial)

          if (error) {
            this.catchErrorRefresh = true
            this.tryTokenTextError = error
            this.textDanger = [1]
            this.textMarginless = [1]
            this.tryToken = require('../assets/icons/error.svg')
            reject(errorReject || error)
          } else {
            resolve(this.tryToken)
          }
        })
      },
      checkObjectsGOST () {
        return new Promise((resolve, reject) => {
          let isKeyExist = false
          for (let i = 0; i < this.objectsGOST.length; i++) {
            this.contName = this.objectsGOST[i].name
            if (this.objectsGOST[i].name === this.order) {
              isKeyExist = true
            }
          }
          if (!isKeyExist) {
            this.catchErrorRefresh = true
            this.setCertificateGOSTText = this.$i18n.t('Installed certificate by order', [this.order, this.contName]).toString()
            this.textDanger = [2]
            this.textMarginless = [2]
            this.setCertificateGOST = require('../assets/icons/error.svg')
            reject(this.setCertificateGOSTText)
          } else {
            console.log('GOST key exist');
            resolve(this.order)
          }
        })
      },
      checkObjectsRSA () {
        return new Promise((resolve, reject) => {
          let isKeyExist = false
          for (let i = 0; i < this.objectsRSA.length; i++) {
            this.contName = this.objectsRSA[i].name
            if (this.objectsRSA[i].name === this.order) {
              isKeyExist = true
            }
          }
          if (!isKeyExist) {
            this.catchErrorRefresh = true
            this.setCertificateRSAText = this.$i18n.t('Installed certificate by order').toString() + ' ' + this.order + ' ' + this.$i18n.t('does not match the key by order').toString() + ' ' + this.contName
            this.textDanger = [3]
            this.textMarginless = [3]
            this.setCertificateRSA = require('../assets/icons/error.svg')
            reject(this.setCertificateRSAText)
          } else {
            console.log('RSA key exist');
            resolve(this.order)
          }
        })
      },
      async deleteKey () {
        this.objectsGOST = JSON.parse(this.objectsGOST.objects)
        this.objectsGOST = this.objectsGOST.objects
        this.objectsRSA = JSON.parse(this.objectsRSA.objects)
        this.objectsRSA = this.objectsRSA.objects
        if (this.objectsGOST) {
          for (let i = 0; i < this.objectsGOST.length; i++) {
            if ((this.objectsGOST[i].name).indexOf('G') === 0) { // наш ключ
              if (this.objectsGOST[i].name !== this.order) { // нужно удалить все объекты, кроме нового
                if (this.objectsGOST[i].cert_b64) {
                  await this.cryptoSocket.DeleteKeyPair({ id: 5, reader: this.reader, pass: this.password, objectName: this.objectsGOST[i].name, alg: 'GOST' })
                }
              }
            }
          }
        }
        if (this.objectsRSA) {
          for (let i = 0; i < this.objectsRSA.length; i++) {
            if ((this.objectsRSA[i].name).indexOf('G') === 0) { // наш ключ
              if (this.objectsRSA[i].name !== this.order) { // нужно удалить все объекты, кроме нового
                if (this.objectsRSA[i].cert_b64) {
                  await this.cryptoSocket.DeleteKeyPair({ id: 6, reader: this.reader, pass: this.password, objectName: this.objectsRSA[i].name, alg: 'RSA' })
                }
              }
            }
          }
        }
      },
      TryAgainRefresh () {
        location.reload()
      },
      nextStepPromise () {
        return new Promise(resolve => {
          const vm = this
          setTimeout(() => {
            vm.$emit('update:currentForm', 'lastForm')
            resolve('lastForm')
          }, 2000)
        })
      },
      //for commit
      async checkAll () {
        try {
          this.startStepOne()
          this.devices = await this.cryptoSocket.getDevices({ id: 6 })
          this.devices = this.devices.devices
          this.reader = this.devices[0].reader
          this.objectsGOST = await this.cryptoSocket.getObjects({ id: 3, reader: this.reader, pass: this.password, alg: 'GOST' })
          this.objectsRSA = await this.cryptoSocket.getObjects({ id: 4, reader: this.reader, pass: this.password, alg: 'RSA' })
          this.serialNumber = await this.cryptoSocket.GetSerialNumber({ id: 2, reader: this.reader, alg: 'GOST' })
          console.log(this.reader);
          this.memoryGOST = await this.cryptoSocket.GetFreeMemorySize({ id: 3, reader: this.reader, alg: 'GOST' })
          this.memoryRSA = await this.cryptoSocket.GetFreeMemorySize({ id: 4, reader: this.reader, alg: 'RSA' })
          await this.checkToken()
          this.endStepOne()
          this.startStepTwo()
          await this.checkObjectsGOST()
          await this.cryptoSocket.SetCertificate({ id: 1, reader: this.reader, pass: this.password, objectName: this.order, cert: this.certificates[1].certificate, alg: 'GOST' })
          this.endStepTwo()
          this.startStepThree()
          await this.checkObjectsRSA()
          await this.cryptoSocket.SetCertificate({ id: 2, reader: this.reader, pass: this.password, objectName: this.order, cert: this.certificates[0].certificate, alg: 'RSA' })
          this.objectsGOST = await this.cryptoSocket.getObjects({ id: 5, reader: this.reader, pass: this.password, alg: 'GOST' })
          this.objectsRSA = await this.cryptoSocket.getObjects({ id: 6, reader: this.reader, pass: this.password, alg: 'RSA' })
          await this.deleteKey()
          this.endStepThree()
          await this.nextStepPromise()
        } catch (e) {
          if (e.Function === 'SetCertificate' && e.id === '1') {
            this.textDanger = [2]
            this.textMarginless = [2]
            this.setCertificateGOST = require('../assets/icons/error.svg')
            this.setCertificateGOSTText = this.$i18n.t('Cannot install certificate').toString() + ' GOST'
          }
          if (e.Function === 'SetCertificate' && e.id === '2') {
            this.textDanger = [3]
            this.textMarginless = [3]
            this.setCertificateRSA = require('../assets/icons/error.svg')
            this.setCertificateRSAText = this.$i18n.t('Cannot install certificate').toString() + ' RSA'
          }
        }
      }
    },
    watch: {
      allChecked: {
        handler: function (val) {
          if (!val) {
            this.checkAll()
          }
        },
        immediate: true
      }
    }
  }
</script>
