<template>
  <div>

    <steps :passive-steps="[]" :active-step="7" :finished-steps="[1, 2, 3, 4, 5, 6]"/>

    <section class="section">
      <div class="container has-text-centered">
        <h1 class="title is-3">{{ $t('Step 7. Certificate installation on security token') }}</h1>
        <p class="title is-5">{{ $t('New certificates corresponding to your new keys, will be installed on your security token') }}.<br>
        {{ $t('Old keys and certificates will no longer be available') }}.</p>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-6">
              <form @submit.prevent="GoToStepSevenDotTwo">

                <label class="label">{{ $t('Enter the password to access the security token') }}*:</label>

                <div class="field has-addons">
                  <div class="control">
                    <input v-model.trim="password" name="password" v-validate="'required'" class="input is-medium" :class="{'has-error': errors.has('password')}"  type="password" v-bind:placeholder="$t('Enter the password')">
                  </div>
                  <div class="control">
                    <button type="submit" class="button is-primary is-medium" :class="{'is-loading': isLoading}">{{buttonPass}}</button>
                  </div>
                </div>
                <p v-if="errors.has('password')" class="alert-error ">{{errors.first('password')}}</p>

                <p v-if="mess" class="alert-error ">{{mess}}</p>
                <TokenHelpText v-if="tokenPage" param="step"/>
                <p class="help" v-html="helpText"></p>

                <div style="height:1rem;"></div>
                <p>{{ $t('Not recommended to change the password') }}!</p>

                <div style="height: 5rem;"></div>
                <keyProblem/>

              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
import keyProblem from '../components/KeyProblem'
import TokenHelpText from '../steps/TokenHelpPage'
export default {
  name: 'OrderStepSevenDotOne',
  components: {
    steps,
    keyProblem,
    TokenHelpText
  },
  props: [
    'currentForm',
    'dataStepSevenDotOne'
  ],
  data () {
    return {
      orderModalActive: false,
      password: null,
      mess: null,
      helpText: this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString(),
      devices: null,
      buttonPass: this.$i18n.t('Install the certificates').toString(),
      isLoading: false,
      tokenPage: null
    }
  },
  methods: {
    openOrderModal () {
      this.orderModalActive = !this.orderModalActive
    },
    goNext () {
      this.$emit('update:currentForm', 'FormStepSevenDotTwo')
      this.$emit('update:dataStepSevenDotOne', {
        password: this.password,
      })
    },
    async check () {
      try {
        this.devices = await this.cryptoSocket.getDevices({ id: 5 })

        await this.cryptoSocket.CheckPassword({ id: 3, reader: this.devices.devices[0].reader, pass: this.password, alg: 'GOST' })
        await this.cryptoSocket.CheckPassword({ id: 4, reader: this.devices.devices[0].reader, pass: this.password, alg: 'RSA' })
        this.goNext()
      } catch (error) {
        console.log(error)
        this.isLoading = false
        this.buttonPass = this.$i18n.t('Install the certificates').toString()
        if (error.Function === 'getDevices') {
          this.mess = this.$i18n.t('Security token not found or not supported').toString()
          this.tokenPage = true
        }
        if (error.Function === 'CheckPassword') {
            this.mess = this.$i18n.t('Security token access error, maybe the password is invalid').toString()
            this.helpText = this.$i18n.t('If the number of incorrect password attempts to exceed the allowed number (by default - three), the security token will be locked').toString()
        }
      }
    },
    GoToStepSevenDotTwo () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.isLoading = true
          this.buttonPass = ''
          console.log('sevenDotOne')
          this.check()
        }
      }).catch(error => console.log(error.message))
    }
  }
}
</script>

<style scoped lang="scss">
  .has-error {
    border-color: red;
  }
  .alert-error {
    color: red;
  }
  .modal-card-title{
    width: 80%;
    color:red;
  }
</style>
}
