<template>
  <div>

    <section class="section">
      <div class="container">
        <div class="content">
          <div class="columns is-centered">
            <div class="column is-8">
              <article class="message is-primary">
                <div class="message-header">
                  <p>{{ $t('Information about your order') }}</p>
                </div>
                <div class="message-body has-user-border">
                  <table>
                    <tbody>
                    <tr>
                      <td class="is-3">{{ $t('Order number') }}</td>
                      <td class="is-9">{{order}}</td>
                    </tr>
                    <tr v-if="orgName">
                      <td v-if="role == '2'">{{ $t('Individual entrepreneur name') }}</td>
                      <td v-else>{{ $t('Company name') }}</td>
                      <td>{{orgName}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Order status') }}</td>
                      <td>{{status}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Full name') }}</td>
                      <td>{{fio}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Email') }}</td>
                      <td>{{email}}</td>
                    </tr>
                    <tr>
                      <td>{{ $t('Serial number of the security token') }}</td>
                      <td>{{serial}}</td>
                    </tr>
                    <tr v-if="revstatus">
                      <td>{{ $t('Order Status for Certificate Revocation') }}</td>
                      <td>{{revstatus}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import steps from '../steps/Steps'
export default {
  name: 'OrderStepSix',
  components: {
    steps
  },
  props: [
    'currentForm',
    'params'
  ],
  computed: {
    lang () {
      return this.$route.query.lang ? this.$route.query.lang : null
    },
    status () {
      if (this.params) {
        if (this.lang == 'ru') {
          return this.params.status
        } else if (this.lang == 'en') {
          return this.params.status_en
        } else if (this.lang == 'kk') {
          return this.params.status_kaz
        } else {
          return null
        }
      } else {
        return null
      }
    },
    order () {
      return this.params ? this.params.order : null
    },
    orgName () {
      return this.params ? (this.params.legal ? this.params.legal + ' ' + this.params.name : this.params.name) : null
    },
    fio () {
      return this.params ? this.params.fio : null
    },
    email () {
      return this.params ? this.params.e_mail : null
    },
    phone () {
      return this.params ? this.params.tel_number : null
    },
    serial () {
      return this.params ? this.params.device : null
    },
    revstatus () {
      return this.params ? (this.lang === 'kk' ? (this.params.revstatus_kaz) : (this.lang === 'ru' ? this.params.revstatus : this.params.revstatus_en)) : null
    },
    role () {
      return this.params ? this.params.role : null
    }
  }
}
</script>
