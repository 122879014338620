<template>
    <div>
      <OrderStepSix
        v-if="currentForm === 'FormStepSix'"
        v-bind:current-form.sync="currentForm"
        v-bind:params="params"
      />
      <OrderStepSixDotOne
        v-if="currentForm === 'FormStepSixDotOne'"
        v-bind:current-form.sync="currentForm"
        v-bind:order="order"
        v-bind:params="params"
        v-bind:certificates="certificates"
      />
      <OrderStepSeven
        v-if="currentForm === 'FormStepSeven'"
        v-bind:current-form.sync="currentForm"
      />
      <OrderStepSevenDotOne
        v-if="currentForm === 'FormStepSevenDotOne'"
        v-bind:current-form.sync="currentForm"
        v-bind:certificates="certificates"
        v-bind:data-step-seven-dot-one.sync="dataStepSevenDotOne"
      />
      <OrderStepSevenDotTwo
        v-if="currentForm === 'FormStepSevenDotTwo'"
        v-bind:current-form.sync="currentForm"
        v-bind:params-for-order="paramsForOrder"
        v-bind:data-step-seven-dot-two.sync="dataStepSevenDotTwo"
      />
      <OrderStepSevenDotThree
        v-if="currentForm === 'FormStepSevenDotThree'"
        v-bind:current-form.sync="currentForm"
        v-bind:order="order"
        v-bind:params="params"
      />
    </div>
</template>

<script>
import OrderStepSix from '../steps/OrderStepSix'
import OrderStepSixDotOne from '../steps/OrderStepSixDotOne'
import OrderStepSeven from '../steps/OrderStepSeven'
import OrderStepSevenDotOne from '../steps/OrderStepSevenDotOne'
import OrderStepSevenDotTwo from '../steps/OrderStepSevenDotTwo'
import OrderStepSevenDotThree from '../steps/OrderStepSevenDotThree'
import fileSaver from 'file-saver'
export default {
  name: 'OrderStatus',
  components: {
    OrderStepSix,
    OrderStepSixDotOne,
    OrderStepSeven,
    OrderStepSevenDotOne,
    OrderStepSevenDotTwo,
    OrderStepSevenDotThree
  },
  data () {
    return {
      currentForm: null,
      status: null,
      doc: null,
      params: null,
      certificates: null,
      dataStepSevenDotOne: null,
      dataStepSevenDotTwo: null
    }
  },
  watch: {
    status: {
      handler: function (val) {
        if (!val) {
          if (this.bill) {
            console.log(this.order)
            this.$http.post('/certificate/get-bill', { order: this.order, bill: this.bill }, { responseType: 'blob' }).then(response => {
              if (response.data) {
                const blob = new Blob([response.data], { type: 'application/octet-stream' })
                fileSaver.saveAs(blob, this.order + '_payment.pdf')
              }
            })
          } else {
            this.$http.get('/certificate/get-status/', { params: { order: this.order } }).then(response => {
              if (response.data) {
                this.status = response.data.status
                this.doc = response.data.du
                if (this.status === '1' || this.status === '2' || this.status === '4' || this.status === '6') {
                  this.currentForm = 'FormStepSix'
                } else if (!this.doc && this.status === '3') {
                  this.currentForm = 'FormStepSixDotOne'
                } else if (this.doc && this.status === '3') {
                  this.currentForm = 'FormStepSeven'
                }
              }
            })
          }
        }
      },
      immediate: true
    },
    params: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/certificate/get-params', { params: { order: this.order } }).then(response => {
            if (response.data) {
              this.params = response.data
              console.log(response.data)
            }
          })
        }
      },
      immediate: true
    },
    certificates: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/get-certificates/', { params: { order: this.order } }).then(response => {
            if (response.data) {
              this.certificates = response.data
            }
          })
        }
      },
      immediate: true
    },
    currentForm: {
      handler: function (val) {
        if (val === 'lastForm') {
          this.$http.post('/certificate/close-order', this.paramsForSubmit).then(response => {
            if (response.data) {
              this.currentForm = 'FormStepSevenDotThree'
            }
          })
        }
      }
    }
  },
  mounted () {
    this.startSocket()
  },
  computed: {
    order () {
      return this.$route.query.order
    },
    bill () {
      return this.$route.query.bill
    },
    paramsForOrder () {
      if (this.dataStepSevenDotOne && this.params) {
        return {
          password: this.dataStepSevenDotOne.password,
          serial: this.params.device,
          order: this.order,
          certificates: this.certificates
        }
      } else return null
    },
    paramsForSubmit () {
      if (this.order && this.params) {
        return {
          order: this.order,
          orderID: this.params.orderid,
          email: this.params.e_mail,
          orderType: this.params.order_type
        }
      } else return null
    }
  }
}
</script>
