<template>
  <div>
    <div class="columns is-gapless is-multiline">
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(1), 'is-active-step': isActiveStep(1), 'is-finished-step': isFinishedStep(1)}">
          <p class="is-marginless">{{ $t('Step 1') }}</p>
          <p class="">{{ $t('Checking your settings') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(2), 'is-active-step': isActiveStep(2), 'is-finished-step': isFinishedStep(2)}">
          <p class="is-marginless">{{ $t('Step 2') }}</p>
          <p class="">{{ $t('Accepting CA Certificate Policies') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(3), 'is-active-step': isActiveStep(3), 'is-finished-step': isFinishedStep(3)}">
          <p class="is-marginless">{{ $t('Step 3') }}</p>
          <p class="">{{ $t('Completing the order form') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(4), 'is-active-step': isActiveStep(4), 'is-finished-step': isFinishedStep(4)}">
          <p class="is-marginless">{{ $t('Step 4') }}</p>
          <p class="">{{ $t('Keys creation process') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(5), 'is-active-step': isActiveStep(5), 'is-finished-step': isFinishedStep(5)}">
          <p class="is-marginless">{{ $t('Step 5') }}</p>
          <p class="">{{ $t('Data analysis and payment order') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small" :class="{'is-passive-step': isPassiveStep(6), 'is-active-step': isActiveStep(6), 'is-finished-step': isFinishedStep(6)}">
          <p class="is-marginless">{{ $t('Step 6') }}</p>
          <p class="">{{ $t('Signing the data') }}</p>
        </div>
      </div>
      <div class="column">
        <div class="content is-small is-no-border-right" :class="{'is-passive-step': isPassiveStep(7), 'is-active-step': isActiveStep(7), 'is-finished-step': isFinishedStep(7)}">
          <p class="is-marginless">{{ $t('Step 7') }}</p>
          <p class="">{{ $t('Sending a request') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Steps',
  props: {
    passiveSteps: {
      type: Array,
      default: []
    },
    activeStep: {
      type: Number,
      default: 0
    },
    finishedSteps: {
      type: Array,
      default: []
    }
  },
  methods: {
    isPassiveStep (id) {
      return this.passiveSteps.find(step => step === id)
    },
    isActiveStep (id) {
      return id === this.activeStep
    },
    isFinishedStep (id) {
      return this.finishedSteps.find(step => step === id)
    }
  }
}
</script>

<style scoped>
  .is-active-step {
    background-color: #3baed9;
    color: white;
    padding: 10px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    height: 100%;
  }
  .is-passive-step {
    background-color: #ededed;
    color: #838383;
    padding: 10px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    height: 100%;
  }
  .is-finished-step {
    background-color: #3bd97e;
    color: white;
    padding: 10px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    height: 100%;
  }
  .is-no-border-right {
    border-right: none;
  }
</style>
