<template>
<div id="certificateTable">
    <div style="margin: 20px 20px 0 0; font-family: Times New Roman !important; font-size: 14px !important; word-wrap: break-word;" border="0">
        <p style="text-align: center;"><b>{{ $t('Registration Certificate') }}</b></p>
        <p>{{ $t('Version') }}: V3</p>
        <p>{{ $t('Registration Certificate serial number') }}: {{serialNumber}}</p>
        <p>{{ $t('EDS algorithm identifier') }}: 1.3.6.1.4.1.6801.1.2.2 (1.2.398.3.10.1.1.1.2)</p>
        <p>{{ $t('Registration Signature Publisher') }}: {{supplier}}</p>
        <p>{{ $t('Algorithm of Cryptographic transformation of Publisher of the Registration Certificate') }}: {{OID}}</p>
        <p>{{ $t('Registration Certificate validity period: Valid from', [validFrom, validTo]) }}</p>
        <p>{{ $t('Registration Certificate Holder') }}: {{owner}}</p>
        <p>{{ $t('Private key of the Registration Certificate Holder: key length: 256 bit') }}</p>
        <p v-html="$t('Public key of the Registration Certificate Holder: key length: 512 bit value')">: <span v-html="publicKey"></span></p>
        <p>{{ $t('Purpose of the key') }}: {{keyUsage}}</p>
        <p>{{ $t('Application area') }}:</p>
        <table style="font-family: Times New Roman !important; font-size: 14px !important; padding: 0; margin: 0;" border="0">
            <tr><td width="70px">{{ $t('Flag') }}</td><td>{{ $t('Key application') }}</td></tr>
            <tr><td>0x80</td><td>{{ $t('Digital Signature') }}</td></tr>
            <tr><td>0x40</td><td>{{ $t('Non-Repudiation') }}</td></tr>
            <tr><td>0x20</td><td>{{ $t('Key Encipherment') }}</td></tr>
            <tr><td>0x10</td><td>{{ $t('Data Encipherment') }}</td></tr>
            <tr><td>0x08</td><td>{{ $t('Key Agreement') }}</td></tr>
            <tr><td>0x04</td><td>{{ $t('Certificate Signing') }}</td></tr>
            <tr><td>0x02</td><td>{{ $t('CRL Signing') }}</td></tr>
        </table>
        <p v-if="role === '4'">{{ $t('EDS means: ТУМАР-CSP') }}</p>
        <p>{{ $t('Registration Certificate in Base64: see Annex') }}</p>
        <p>{{ $t('Publisher’s EDS under this Registration Certificate') }}: <span v-html="signature"></span></p>
        <p>{{ $t('Certificate Date') }}: {{ date | moment("DD MMMM YYYY") }}</p>
        <p>{{ $t('Full name') }}: {{fio}}</p>
        <p>{{ $t('Order number') }}: {{order}}</p>
        <p>{{ $t('Signature') }}: _________________</p>
        <br><br><br><br><br><br><br><br><br><br><br><br>
        <p style="text-align: center;"><b>{{ $t('Annex') }}</b></p>
        <p>{{certificate}}</p>
    </div>
</div>
</template>

<script>
export default {
  name: 'Certificate',
  props: [
    'params',
    'serialNumber',
    'validFrom',
    'validTo',
    'supplier',
    'owner',
    'publicKey',
    'OID',
    'policy',
    'supplierKeyID',
    'ownerKeyID',
    'keyUsage',
    'extendedKeyUsage',
    'signature',
    'certificate',
    'fio',
    'order',
    'role'
  ],
  computed: {
    date () {
      return new Date()
    }
  }
}
</script>

<style scoped>
  #certificateTable {
    display: none;
  }
</style>
